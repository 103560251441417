import n from"../../_snowpack/pkg/react.js";import o from"../../_snowpack/pkg/styled-components.js";function a({id:e,name:t,spriteUrl:i}){return n.createElement(r,{src:i,alt:t,title:t,"data-pokemon-id":e,width:"465",height:"465"})}const r=o.img`
	display: block;
	inline-size: 100%;
	block-size: auto;
	margin-inline-start: auto;
	margin-inline-end: auto;
	aspect-ratio: 1/1;
	grid-column: 1 / -1;
	grid-row: 2 / span 1;

	@media screen and (orientation: landscape) {
		grid-column: 1 / span 1;
		grid-row: 1 / -1;
	}
`;export default a;
